"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineInstallPage = exports.InstallPage = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const apis_1 = require("./apis");
class InstallPage extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.style.setProperty('display', 'block');
        this.innerHTML = `
    <div class="install-form-and-button">
      <div class="install-form">
        <div class="form-field-name">Admin ID</div><div class="form-field-value"><input class="admin-id" type="text" /></div>
        <div class="form-field-name">Password</div><div class="form-field-value"><input class="password" type="password"/></div>
        <div class="form-field-name">Confirm Password</div><div class="form-field-value"><input class="confirm-password" type="password" /></div>
        <div class="form-field-name">Server Name</div><div class="form-field-value"><input class="server-name" /></div>
      </div>
      <button class="install">Install</button>
    </div>`;
        const adminIdInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.admin-id');
        const passwordInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.password');
        const confirmPasswordInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.confirm-password');
        const serverNameInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.server-name');
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.install').addEventListener('click', () => {
            if (adminIdInput.value === '') {
                alert('input admin id');
                return;
            }
            if (passwordInput.value === '') {
                alert('input password');
                return;
            }
            if (passwordInput.value !== confirmPasswordInput.value) {
                alert('passwords are not same');
                return;
            }
            if (serverNameInput.value === '') {
                alert('input server name');
                return;
            }
            void (() => __awaiter(this, void 0, void 0, function* () {
                const resp = yield this.apis.install({ server_name: serverNameInput.value, admin_id: adminIdInput.value, password: passwordInput.value });
                if (resp === null) {
                    alert('fail to install server');
                    return;
                }
                alert('install server successfully');
            }))();
        });
    }
}
exports.InstallPage = InstallPage;
const defineInstallPage = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, InstallPage);
};
exports.defineInstallPage = defineInstallPage;
